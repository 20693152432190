<template>
  <div class="card p-2">
    <div>
        <h1 class="text-center">Company Name</h1>
        <h1 class="text-center">Comparative Statement Of Price Quotation</h1>
    </div>
    <section class="spec">
        <span>CS Number:</span>
        <span>PR No:</span>
        <span>CS Date:</span>
        <span>PR Department:</span>
    </section>
    <div class="cs-table table-responsive mt-2 pb-2">
      <table class="table table-bordered">
        <thead>
        <tr>
          <th colspan="2" class="text-center">Supplier Details</th>
          <th colspan="5" class="text-center">Premier Scientific Stores</th>
          <th colspan="5" class="text-center">Al Amin and Company</th>
          <th colspan="5" class="text-center">Texsol BD</th>
        </tr>
        <tr>
          <th>Particulars</th>
          <th>Req Qty</th>
          <th>Specification</th>
          <th>Unit price</th>
          <th>VAT %</th>
          <th>VAT Amount</th>
          <th>Total Amount</th>
          <th>Specification</th>
          <th>Unit price</th>
          <th>VAT %</th>
          <th>VAT Amount</th>
          <th>Total Amount</th>
          <th>Specification</th>
          <th>Unit price</th>
          <th>VAT %</th>
          <th>VAT Amount</th>
          <th>Total Amount</th>
        </tr>
        </thead>
        <tbody>
        <tr>
            <td>Celling Fan 32"</td>
            <td>22</td>
            <template v-for="n in 3" :key="n">
                <td>National Fan 32 "</td>
                <td class="text-right">{{commaFormat(4500)}}</td>
                <td class="text-right">15%</td>
                <td class="text-right">{{commaFormat(673)}}</td>
                <td class="text-right">{{commaFormat(103500)}}</td>
            </template>
        </tr>

        <tr>
            <td><strong>Total</strong></td>
            <td colspan="6" class="text-right"><strong>{{commaFormat(103500)}}</strong> </td>
            <td colspan="5" class="text-right"><strong>{{commaFormat(342432)}}</strong> </td>
            <td colspan="5" class="text-right"><strong>{{commaFormat(22332)}}</strong> </td>
        </tr>
        <tr>
            <td colspan="17">-</td>
        </tr>
        <tr>
            <td colspan="17">*Time Required For Supply</td>
        </tr>
        <tr>
            <td colspan="17">*Payment Terms</td>
        </tr>
        </tbody>
      </table>
      <!-- <p v-if="!journals.length" class="text-center mt-5">No data</p> -->
      <div class="mt-2 bg-black-light px-1">
        <strong>Special reason of selecting price for supplier at a higher rate</strong>
      </div>
    </div>

    <div class="cs-table table-responsive mt-2">
      <table class="table table-bordered">
        <thead>
        <tr>
          <th colspan="17">C.S Committee Members</th>
        </tr>
        <tr>
          <th>Name</th>
          <th colspan="15" class="text-center">Comment</th>
          <th>Signature</th>
        </tr>
        </thead>
        <tbody>
        <tr>
            <td>Committee member name</td>
            <td colspan="15" class="text-center">Auto comments during approval</td>
            <td>-</td>
        </tr>
        
        </tbody>
      </table>
    </div>

    <div class="mt-4">
        <h3 class="text-right">Approved By</h3>
    </div>
  </div>
</template>

<script setup>
import figureFormatter from '@/services/utils/figureFormatter'
const {commaFormat} = figureFormatter ()
</script>

<style scoped>
.spec {
    display: grid;
    grid-template-columns: auto auto;
    grid-row-gap: 10px;
    justify-content: space-between;
}

.cs-table th,
.cs-table td
 {
    padding: 5px 10px;
    white-space: nowrap;
}
</style>
